import { Navigate } from "react-router-dom";
import { getCookie } from "../util/cookie";
import { Props } from "../types/types";

interface AuthProps extends Props {
  roles?: string[];
}

const ProtectedRoute = (props: AuthProps) => {
  const _username = getCookie("username");
  const role = getCookie("role");
  const authKey = getCookie("authKey");
  const sessionKey = getCookie("sessionKey");
  // Check authentication
  if (!(_username && role && authKey && sessionKey)) {
    return <Navigate to="/" />;
  } else {
    // validate session key and verify roles
  }
  const roles = props.roles || ["viewer", "editor", "admin"];
  // Check authorization
  if (!roles.includes(role)) {
    return <Navigate to="/" />;
  }
  return <>{props.children}</>;
};

export default ProtectedRoute;
