import React, { createContext, useState } from "react";
import { AuthUser } from "../types/types";
import { getCookie } from "../util/cookie";

const AuthContext = createContext({
  auth: {
    username: "",
    role: "",
    authKey: "",
    sessionKey: "",
  },
  setAuth: null,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [auth, setAuth] = useState<AuthUser>({
    username: getCookie("username"),
    role: getCookie("role"),
    authKey: getCookie("authKey"),
    sessionKey: getCookie("sessionKey"),
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
