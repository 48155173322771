
const Unauthorized = () => {
    return (
        <section className="registration">
            <p>Unauthorized</p>
        </section>
    )
}

export default Unauthorized
