import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Banner.css";
import { useEffect, useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface BannerProps {
  message: string;
}

const BANNER_STAY_LENGTH = 4000;

const Banner = (props: BannerProps) => {
  const { message } = props;

  const [showBanner, setShowBanner] = useState<boolean>(false);

  const closeBanner = () => setShowBanner(false);

  useEffect(() => {
    setTimeout(closeBanner, BANNER_STAY_LENGTH);
  }, [message]);

  return (
    showBanner && (
      <div className="banner">
        {message}
        <FontAwesomeIcon icon={faClose} onClick={closeBanner} />
      </div>
    )
  );
};

export default Banner;
