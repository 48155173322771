import { useContext } from "react";
import { getCookie } from "../util/cookie";
import { Props } from "../types/types";
import AuthContext from "../context/AuthProvider";

interface AuthProps extends Props {
  roles?: string[];
}

const ProtectedElement = (props: AuthProps) => {
  const { auth } = useContext(AuthContext);

  const _username = auth.username || getCookie("username");
  const role = auth.role || getCookie("role");
  const authKey = auth.authKey || getCookie("authKey");
  const sessionKey = auth.sessionKey || "sessionKey";

  const roles = props.roles || ["viewer", "editor", "admin"];

  // Check authentication
  if (!(_username && role && authKey && sessionKey)) {
    return <>{props.defaultElement}</> || null;
  } else {
    // validate session key and verify roles
  }
  // Check authorization
  if (!roles.includes(role)) {
    return <>{props.defaultElement}</> || null;
  }

  if (props.children) {
    return <>{props.children}</>;
  }
  return <>{props.defaultElement}</>;
};

export default ProtectedElement;
