import { useState, useEffect, useRef, useContext } from "react";

import { setCookie } from "../../util/cookie";
import AuthContext from "../../context/AuthProvider";
import { authServer } from "../../api/axios";
import { useSearchParams, Link } from "react-router-dom";

const CONFIRM_EMAIL_URL = "/confirm_email";

const ConfirmEmail = () => {
  const { setAuth } = useContext(AuthContext);

  const errRef = useRef<HTMLParagraphElement>();

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const confirm = async () => {
      try {
        const response = await authServer.post(CONFIRM_EMAIL_URL, {
          accessKey: searchParams.get("accessKey"),
        });

        setAuth({
          username: response?.data?.username,
          sessionKey: response?.data?.sessionKey,
          role: response?.data?.role,
          authKey: response?.data?.authKey,
        });

        setCookie("username", response?.data?.username);
        setCookie("role", response?.data?.role);
        setCookie("authKey", response?.data?.authKey);
        setCookie("sessionKey", response?.data?.sessionKey);
        setSuccess(true);
      } catch (e) {
        setErrMsg(e.message);

        if (e?.response) {
          setErrMsg("No server response");
        } else if (e.response?.status === 401) {
          setErrMsg("Unauthorized.");
        } else {
          setErrMsg("Login Failed.");
        }
      }
    };
    confirm();
  }, [searchParams, setAuth]);

  return (
    <>
      {success ? (
        <section className="registration">
          <h1>Email confirmed</h1>
          <p>Your email has been confirmed.</p>

          <p>
            Continue to{" "}
            <span className="inline">
              <Link to="/gpt">Phys GPT</Link>
            </span>
          </p>
        </section>
      ) : (
        <section className="registration">
          <p
            ref={errRef}
            className={errMsg ? "error" : "aria-hidden"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h1>We can't find your email</h1>
          <p>There was an error processing your request.</p>
          <div className="login-links">
            <p>
              <span className="inline">
                <Link to="/register">Register again</Link>
              </span>
            </p>
            <p>
              <span className="inline">
                <Link to="/reset_password">Forgot password?</Link>
              </span>
            </p>
          </div>
        </section>
      )}
    </>
  );
};

export default ConfirmEmail;
