import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CopyProps {
  copyText: string;
}

const Copy = (props: CopyProps) => {
  const { copyText } = props;
  const copyHandler = () => {
    navigator.clipboard.writeText(copyText);
  };
  return (
    <FontAwesomeIcon
      icon={faCopy}
      title="Copy to clipboard"
      className="copy-icon"
      onClick={copyHandler}
    />
  );
};

export default Copy;
