import axios from "axios";

const authServer = axios.create({ baseURL: "https://auth.physgpt.com" });

authServer.defaults.headers.post["Accept"] = "application/json";
authServer.defaults.headers.post["Content-Type"] = "application/json";

export { authServer };

const physGPTServer = axios.create({ baseURL: "https://app.physgpt.com" });

physGPTServer.defaults.headers.post["Accept"] = "application/json";
physGPTServer.defaults.headers.post["Content-Type"] = "application/json";

export { physGPTServer };
