import "./App.css";
import Registration from "./components/registration/Registration";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Login from "./components/registration/Login";
import Logout from "./components/registration/Logout";
import SetNewPassword from "./components/registration/SetNewPassword";
import ResetPassword from "./components/registration/ResetPassword";
import UpdatePassword from "./components/registration/UpdatePassword";
import ConfirmEmail from "./components/registration/ConfirmEmail";
import PhysGPT from "./components/app/PhysGPT";
import PDFViewer from "./components/app/PDFViewer";
import UserManagement from "./components/app/UserManagement";
import Unauthorized from "./components/Unauthorized";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./util/ProtectedRoute";
import { getCookie } from "./util/cookie";
import { Routes, Route } from "react-router-dom";

const username = getCookie("username");
const sessionKey = getCookie("sessionKey");
let DefaultPage = Home;

if (username) {
  DefaultPage = Login;
  if (sessionKey) {
    // validate session key and verify roles
  }
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<DefaultPage />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Registration />} />
        <Route path="set_new_password" element={<SetNewPassword />} />
        <Route path="reset_password" element={<ResetPassword />} />
        <Route path="update_password" element={<UpdatePassword />} />
        <Route path="confirm_email" element={<ConfirmEmail />} />
        <Route
          path="user_management"
          element={
            <ProtectedRoute roles={["admin"]}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route
          path="gpt"
          element={
            <ProtectedRoute>
              <PhysGPT />
            </ProtectedRoute>
          }
        />
        <Route
          path="pdf"
          element={
            <ProtectedRoute roles={["editor", "admin"]}>
              <PDFViewer />
            </ProtectedRoute>
          }
        />
        <Route path="unauthorized" element={<Unauthorized />} />
      </Route>
    </Routes>
  );
}

export default App;
