import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <section className="home">
            <p className="center">PhysGPT is a tool for APEC member organizations to use to aid their research.</p><p className="center">This tool is not currently available to the public so check out the links below to see what we're up to.</p>
            <hr />
            <Link to="https://www.altpropulsion.com">
                <div className="apec logo"></div>
            </Link>
            <p className="label">In cooperation with:</p>
            <div className="logo-tray">
                <Link to="https://www.falconspace.org">
                    <div className="falconspace logo"></div>
                </Link>
                <Link to="https://www.sanrel.com">
                    <div className="sanrel logo">Sanrel, LLC</div>
                </Link>
                <Link to="https://alienscientist.com">
                    <div className="aliensci logo"></div>
                </Link>
            </div>
        </section>
    );

}

export default Home
