import "./UserManagement.css";
import { useState, useEffect, useContext, ReactNode } from "react";
import { Link, redirect } from "react-router-dom";
import dayjs from "dayjs";
import {
  faCheck,
  faThumbsDown,
  faThumbsUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../context/AuthProvider";
import { authServer } from "../../api/axios";
import { getCookie } from "../../util/cookie";
import { User } from "../../types/types";
import Copy from "../../components/Copy";
import Banner from "../Banner";

const GET_USERS_URL = "/get_users";
const APPROVE_USER_URL = "/approve_user";
const REJECT_USER_URL = "/reject_user";

const UserManagement = () => {
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [bannerMsg, setBannerMsg] = useState<string>("");

  useEffect(() => {
    const getUsers = async () => {
      const username: string = auth.username || getCookie("username");
      const authKey: string = auth.authKey || getCookie("authKey");
      const sessionKey: string = auth.sessionKey || getCookie("sessionKey");
      const role: string = auth.role || getCookie("role");

      if (role !== "admin") {
        setUsers([]);
        if (sessionKey) {
          return redirect("/gpt");
        }
        return redirect("/logout");
      }

      try {
        const response = await authServer.post(GET_USERS_URL, {
          username: username,
          authKey: authKey,
          sessionKey: sessionKey,
          role: role,
        });

        setUsers(response?.data?.users || []);
      } catch (e) {
        console.log("Error:", e, "\nDisconnecting from server...");
        setUsers([]);
      }
    };
    getUsers();
  }, [auth, bannerMsg]);

  const RoleWrapper = (
    props: Pick<User, "role" | "valid"> & { children: ReactNode }
  ): ReactNode => {
    const { role, valid, children } = props;
    let className = "";

    switch (role) {
      case "viewer":
        className = "viewer";
        break;
      case "admin":
        className = "admin";
        break;
    }

    className += valid ? " validated" : "";

    return <span className={className}>{children}</span>;
  };

  const approveHandlerCreator = (username: string) => {
    return async () => {
      const authUser = {
        username: auth.username || getCookie("username"),
        authKey: auth.authKey || getCookie("authKey"),
        sessionKey: auth.sessionKey || getCookie("sessionKey"),
        role: auth.role || getCookie("role"),
      };
      try {
        const response = await authServer.post(APPROVE_USER_URL, {
          user: authUser,
          data: {
            username,
          },
        });

        if (response.status === 200) {
          setBannerMsg(`Success.  User ${username} activated.`);
        }
      } catch (e) {
        console.log("Error:", e, "\nDisconnecting from server...");
        setUsers([]);
      }
    };
  };

  const rejectHandlerCreator = (username: string) => {
    return async () => {
      const authUser = {
        username: auth.username || getCookie("username"),
        authKey: auth.authKey || getCookie("authKey"),
        sessionKey: auth.sessionKey || getCookie("sessionKey"),
        role: auth.role || getCookie("role"),
      };
      try {
        const response = await authServer.post(REJECT_USER_URL, {
          user: authUser,
          data: {
            username,
          },
        });

        if (response.status === 200) {
          setBannerMsg(`Success.  User ${username} rejected.`);
        }
      } catch (e) {
        console.log("Error:", e, "\nDisconnecting from server...");
        setUsers([]);
      }
    };
  };

  return (
    <section className="user-management">
      <Banner message={bannerMsg} />
      <h1>User Management</h1>
      <div className="users-table">
        <div className="users-header">
          <div className="header-item">User Name</div>
          <div className="header-item">Role</div>
          <div className="header-item">Created</div>
          <div className="header-item">Last Active</div>
          <div className="header-item">Valid</div>
        </div>
        {users.map((user) => {
          return (
            <div key={`user-${user.id}`} className="users-row">
              <div className="users-item">
                <RoleWrapper role={user.role} valid={user.valid}>
                  {user.name}
                </RoleWrapper>
                <Copy copyText={user.name} />
              </div>
              <div className="users-item">
                <RoleWrapper role={user.role} valid={user.valid}>
                  {user.role}
                </RoleWrapper>
              </div>
              <div className="users-item">
                {dayjs(user.created).format("MM/DD/YYYY")}
              </div>
              <div className="users-item">
                {dayjs(user.active).format("MM/DD/YYYY")}
              </div>
              <div className="users-item">
                {user.valid ? (
                  user.role === "viewer" ? (
                    <>
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className="approve"
                        onClick={approveHandlerCreator(user.name)}
                        title="Approve"
                      />
                      <FontAwesomeIcon
                        icon={faThumbsDown}
                        className="reject"
                        onClick={rejectHandlerCreator(user.name)}
                        title="Reject"
                      />
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faCheck} className="green" />
                  )
                ) : (
                  <FontAwesomeIcon icon={faTimes} className="red" />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Link to="/gpt">Back to GPT</Link>
    </section>
  );
};

export default UserManagement;
