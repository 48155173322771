import { useEffect, useContext } from "react";

import AuthContext from "../../context/AuthProvider";
import { authServer } from "../../api/axios";
import { Navigate } from "react-router-dom";
import { getCookie, setCookie } from "../../util/cookie";

const LOGOUT_URL = "/logout";

const Logout = () => {
  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    const logout = async () => {
      const username: string = auth.username || getCookie("username");
      const authKey: string = auth.authKey || getCookie("authKey");
      const sessionKey: string = auth.sessionKey || getCookie("sessionKey");

      try {
        await authServer.post(LOGOUT_URL, {
          username,
          authKey,
          sessionKey,
        });
      } catch (e) {
        console.log("Error disconnecting from server.", e);
      }

      setAuth({});
      // Clear cookies
      setCookie("username", null);
      setCookie("authKey", null);
      setCookie("role", null);
      setCookie("sessionKey", null);
    };
    logout();
  });

  return <Navigate to="/" />;
};

export default Logout;
